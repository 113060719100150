<template>
  <div>
    <a-card title="仓库管理">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_active" placeholder="状态" style="width: 100%" @change="search">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="true">激活</a-select-option>
            <a-select-option :value="false">冻结</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="6" :xl="8" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增仓库</a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ y: 480 }"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" @update="update" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { warehouseList, warehouseDestroy, getWarehousesNumber } from "@/api/data";
import { warehouseExport } from "@/api/export";
import { warehouseTemplate, warehouseImport } from "@/api/import";
import { exportExcel } from "@/utils/excel";
import columns from "./columns.js";

export default {
  name: "Warehouse",
  components: {
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", is_active: undefined, page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: { is_active: true },
      importLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();
    },
    list() {
      this.loading = true;
      warehouseList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      this.items.splice(0, 0, item);
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    destroy(id) {
      warehouseDestroy({ id }).then(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id == id),
          1
        );
        this.$message.success("删除成功");
      });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    openFormModal(item) {
      if (!item.id) {
        getWarehousesNumber().then((data) => {
          this.targetItem = { ...item, ...{ number: data.number } };
        });
      } else {
        this.targetItem = { ...item };
      }
      this.visible = true;
    },
    exportExcel() {
      warehouseExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, "仓库列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      warehouseTemplate()
        .then((resp) => {
          exportExcel(resp, "仓库导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        warehouseImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
